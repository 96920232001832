<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Yedek Parça</b-card-title>
    </b-card-header>
    <b-table
      v-if="spareparts.data.length > 0"
      responsive="sm"
      :fields="fields"
      :items="spareparts.data"
      striped
    >
      <template #cell(count)="data">
        <div class="text-primary">
          {{ data.item.count }}
          {{ data.item.unit }}
        </div>
      </template>
    </b-table>
    <empty-alert v-else />
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BTable,
} from 'bootstrap-vue'
import EmptyAlert from '@/views/Admin/Reports/common/EmptyAlert.vue'

export default {
  name: 'Spareparts',
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BTable,
    EmptyAlert,
  },
  data() {
    return {
      fields: [
        {
          key: 'title',
          label: 'BAŞLIK',
        },
        {
          key: 'count',
          label: 'Miktar',
        },
      ],
    }
  },
  computed: {
    spareparts() {
      return this.$store.getters['serviceReports/spareparts']
    },
  },
}
</script>

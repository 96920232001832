<template>
  <b-row v-if="summary.length > 0">
    <b-col
      v-for="(item,key) in summary"
      :key="key"
    >
      <b-card no-body>
        <b-card-body class="d-flex justify-content-between align-items-center">
          <div class="truncate text-nowrap">
            <h2 class="mb-25 font-weight-bolder">
              {{ item.count }}
            </h2>
            <span>{{ item.title }}</span>
          </div>
          <b-avatar
            :variant="`light-${item.variant}`"
            size="45"
          >
            <feather-icon
              size="21"
              :icon="item.icon"
            />
          </b-avatar>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BRow, BCol, BCardBody, BAvatar,
} from 'bootstrap-vue'

export default {
  name: 'Summary',
  components: {
    BCard,
    BRow,
    BCol,
    BCardBody,
    BAvatar,
  },
  computed: {
    summary() {
      return this.$store.getters['serviceReports/summary']
    },
  },
}
</script>

<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Süre Ortalaması</b-card-title>
    </b-card-header>
    <b-table
      v-if="timeAverage.data.length > 0"
      responsive="sm"
      :fields="fields"
      :items="timeAverage.data"
      striped
    >
      <template #cell(response_time)="data">
        <div class="text-primary">
          <span
            v-if="data.item.response_time.days"
            class="mr-1"
          >{{ data.item.response_time.days }} Gün</span>
          <span
            v-if="data.item.response_time.hours"
            class="mr-1"
          >{{ data.item.response_time.hours }} Saat</span>
          <span
            v-if="data.item.response_time.minutes"
          >{{ data.item.response_time.minutes }} Dakika</span>
        </div>
      </template>
    </b-table>
    <empty-alert v-else />
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BTable,
} from 'bootstrap-vue'
import EmptyAlert from '@/views/Admin/Reports/common/EmptyAlert.vue'

export default {
  name: 'TimeAverage',
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BTable,
    EmptyAlert,
  },
  data() {
    return {
      fields: [
        {
          key: 'title',
          label: 'BAŞLIK',
        },
        {
          key: 'response_time',
          label: 'Süre',
        },
      ],
    }
  },
  computed: {
    timeAverage() {
      return this.$store.getters['serviceReports/timeAverage']
    },
  },
}
</script>
